<template>
  <div class="wrap">
    <div class="logins">
      <div class="logins_top">
        <!-- <setLanguage /> -->
        <div class="logins_header_logo">
          <img src="../../assets/new/login/logo.png" alt="">
          <div class="close" @click="$router.push('/tabbar/goods')">
            <van-icon name="cross" size="30" color="#4c4743" />
          </div>
        </div>
      </div>
      <div class="logins_tabs">
        <div class="tab">
          <div class="tab_item" :class="active == index ? 'tab_active' : ''" v-for="item, index in tab" :key="index"
            @click="tabClick(index)">
            {{ $t(item) }}
          </div>
        </div>
      </div>
      <div class="logins_form">
        <Form class="form_list" validate-trigger="onSubmit">
          <Field v-model="formValue[item.key]" :placeholder="$t(item.label)" v-for="(item, index) in formList"
            :key="`form_item_${index}`" :rules="item.rules || []" :maxlength="item.length || 99"
            :type="item.type || 'text'" :border="false">
            <template #left-icon>
              <van-icon :name="item.icon" class="icon" v-if="item.icon" />
              <img class="img" v-if="item.img" :src="item.img" alt="">
            </template>
          </Field>
          <div class="btns">
            <div @click="onSubmit" color="#16a1ba" class="confirm">
              {{ $t('newAdd.Login') }}
            </div>
          </div>
          <div class="btn_group">
            <!-- <div class="service" @click="service">
              {{ $t('login.service') }}
              <img src="../../assets/new/login/service.png" alt="">
            </div>
            <div class="line">|</div> -->
            <div class="register" @click="toRegiste">{{ $t('freeRegistration') }}</div>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<script>
import setLanguage from "@/components/setLanguage.vue";
import { LOGIN } from "@/api";
import { $post } from "../../utils/request";
import { Icon, Field, Form, Button as Btn, Checkbox, Toast, } from "vant";
export default {
  components: {
    [Icon.name]: Icon,
    Field,
    Form,
    Btn,
    Checkbox,
    setLanguage
  },
  data() {
    return {
      formList: [{
        key: "userphone",
        flag: true,
        icon: "contact",
        label: "Login.nameInput",
        rules: [{ required: false, message: this.$t("hint.usernameEnter") }],
      }, {
        label: "Login.pwdInput",
        type: "password",
        key: "userpass",
        img: require('../../assets/new/login/password.png'),
        flag: true,
        rules: [{ required: false, message: this.$t("hint.passwordEnter") }],
        isPassword: true,
      }],
      formValue: {
        userphone: '',
        password: ''
      },
      read: false,
      tab: ['passwordLogin', 'registerAccount'],
      active: 0
    };
  },
  methods: {
    service() {
      this.$store.dispatch("toService");
    },
    onSubmit() {
      if (!this.formValue.userphone) return Toast(this.$t("hint.usernameEnter"));
      if (!this.formValue.userpass) return Toast(this.$t("hint.passwordEnter"));
      Toast.loading({ duration: 0 });
      LOGIN(this.formValue).then((r) => {
        if (r.data.ret === 1) {
          this.$cookie.set("userInfo", JSON.stringify(r.data.data));
          this.$cookie.set("token", r.data.data.token);
          this.$cookie.set("the-pwd-state", this.checked);
          if (this.read) {
            this.$cookie.set(
              "the-pwd-obj",
              JSON.stringify({
                userphone: this.formValue.userphone,
                userpass: this.formValue.userpass,
              })
            );
          } else {
            this.$cookie.remove("the-pwd-obj");
          }
        } else {
          Toast.clear();
          Toast(r.data.msg);
        }
        return r.data.ret;
      }).then(async (ret) => {
        if (ret == 1) {
          this.$store.dispatch("getSystemConfig");
          try {
            const res = await $post("/users/info");
            const { ret, msg, data } = res.data;
            if (ret == 1) {
              Toast.clear();
              this.$router.replace({ name: "goods" });
              this.$cookie.set("userInfo", JSON.stringify(data));
            }
          } catch (error) {
            Toast.clear();
          }
        }
      }).catch((e) => {
        Toast.clear();
      });
    },
    tabClick(index) {
      if (index == 1) {
        this.toRegiste();
      }
    },
    toRegiste() {
      this.$router.push("/registrasi");
    },
  },
};
</script>

<style lang="less" scoped>
.wrap {
  background: var(--bg);
  min-height: 100vh;
  display: flex;
  justify-content: center;
  // padding: 0 calc(20rem / 16);
  background: url('../../assets/new/login/login_bg.png') 0 bottom no-repeat;
  background-size: 100% 100%;

  .logins {
    flex: 1;
    // padding-top: calc(40rem / 16);

    &_top {
      display: flex;
      justify-content: flex-end;
      background-image: url('../../assets/new/login/login_header.png');
      background-size: cover;
      width: 100%;
      height: calc(182rem / 16);
      text-align: center;
      position: relative;
      padding-top: calc(23rem / 16);

      .logins_header_logo {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;

        img {
          width: calc(180rem / 16);
          height: auto;
          display: block;
          margin-top: calc(20rem / 16);
        }
      }

      .close {
        position: absolute;
        z-index: 80;
        top: calc(23rem / 16);
        right: calc(30rem / 16);
      }
    }

    &_tabs {
      display: flex;
      justify-content: center;
      margin-bottom: calc(30rem / 16);
      padding: calc(16rem / 16) calc(20rem / 16) 0;

      .tab {
        display: flex;
        align-items: center;
        // width: calc(192rem / 16);

        &_item {
          color: var(--textColor);
          padding-bottom: calc(6rem / 16);
          border-bottom: 2px solid #D8D8D8;
          padding: 0 calc(4rem / 16) calc(6rem / 16);
          height: 100%;
          display: flex;
          align-items: center;
        }

        .tab_active {
          color: var(--red);
          border-bottom: 2px solid var(--red);
        }
      }
    }


    .common {
      position: relative;
      z-index: 1;
    }

    &_content {
      .common();
      padding: calc(20rem / 16);
      margin-top: calc(10rem / 16);

      .title {
        font-size: calc(24rem / 16);
        font-family: YouSheBiaoTiHei;
        font-weight: normal;
        line-height: calc(30rem / 16);
        font-variation-settings: "opsz" auto;
        color: var(--light);
        text-shadow: 0px 3px 3px #3E8597;
        text-align: center;
      }

      .btn {
        background-image: linear-gradient(179deg, #13a2ba, #087c95);
        color: var(--light);
        width: max-content;
        padding: 0 calc(10rem / 16);
        height: calc(36rem / 16);
        font-size: calc(14rem / 16);
        font-weight: 400;
        border-radius: calc(7rem / 16);
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: calc(10rem / 16);
      }
    }

    &_form {
      .common();
      padding: 0 calc(30rem / 16);

      .form_list {
        /deep/ .van-cell {
          background-color: var(--light);
          padding: calc(6rem / 16) calc(22rem / 16);
          margin-bottom: calc(15rem / 16);
          border-radius: calc(24rem / 16);
          border: 1px solid var(--border1);

          .van-field__left-icon {
            display: flex;
            align-items: center;
          }

          input::placeholder {
            line-height: calc(32rem / 16);
            color: var(--colors);
            font-size: calc(15rem / 16);
          }

          input {
            line-height: calc(32rem / 16);
            color: var(--textColor);
            font-size: calc(15rem / 16);
          }
        }

        .img {
          width: calc(24rem / 16);
          height: calc(24rem / 16);
          margin-right: calc(5rem / 16);
          display: block;
        }

        .icon {
          font-size: calc(20rem / 16);
          margin-right: calc(5rem / 16);
          color: var(--colors);
        }

        .btns {
          margin-top: calc(30rem / 16);

          .confirm {
            height: calc(42rem / 16);
            color: var(--light);
            border-radius: calc(4rem / 16);
            background: var(--btn);
            font-size: calc(17rem / 16);
            display: flex;
            justify-content: center;
            align-items: center;
            flex: 1;
          }
        }

        .btn_group {
          display: flex;
          // justify-content: center;
          align-items: center;
          color: var(--gray1);
          font-size: calc(14rem / 16);
          padding: calc(20rem / 16) 0 calc(10rem / 16);

          .service {
            flex: 0 0 calc(50% - 10rem / 16);
            display: flex;
            align-items: center;
            text-align: right;

            img {
              width: calc(24rem / 16);
              height: calc(24rem / 16);
              display: block;
              margin-left: calc(5rem / 16);
            }
          }

          .line {
            margin: 0 calc(10rem / 16);
          }

          .register {
            flex: 0 0 calc(50% - 10rem / 16);
          }
        }
      }
    }
  }
}
</style>